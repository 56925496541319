<template>
    <div class="layout">
        <div class="container">
            <TopBar />
            <slot />
        </div>
    </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue';
export default {
    components: {
        TopBar,
    }
}
</script>

<style scoped>
.layout {
    background-color: #F9F7F4;
}
.menu-bar {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 0px;
}
  
.item {
    color: #a5a5a5;
    background-color: transparent;
    font-size: 18px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0px 10px;
}
  
.item.title {
    font-weight: 600;
}
  
.item:hover {
    color: #eebc06;
    cursor: pointer;
}

.hero-txt, .form-to-pay {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.headline {
    font-family: "Sofia Sans", sans-serif;
    font-weight: 600;
}

.btn-default {
    background-color: #eebc06;
    border-radius: 50px;
    padding: 10px 40px;
}

.btn-default:hover {
    background-color: #d6cf12;
}

.btn-default-buy {
    background-color: #22cb2d;
    border-radius: 7px;
    padding: 10px 40px;
}

.btn-default-buy:hover {
    background-color: #077d0f;
    color: #F1F1F1;
}

.pack-mini-info {
    text-align: left;
    margin: 0px auto;
    padding: 10px 20px;
}

.pack-name {
    font-weight: bolder;
}

.packs .card { 
    margin-bottom: 10px;
}


.social-item {
    border-radius: 20px;
    color: #F9F7F4;
    background: #BBB;
    font-weight: bold;
    padding: 5px 10px;
    margin: auto 5px;
}

.social-item:hover {
    color: #F1F1F1;
    background-color: #eebc06;
}

.packs > div {
    padding-bottom: 6rem;
}

.image-pack { 
    width: 100%;
}
</style>