<template>
    <div class="pagamento">
        <Base style="min-height: 100vh">
            <section class="form-to-pay">
                <div v-if="!packId">
                    <p> Nenhum pacote selecionado. Volte e tente novamente, por favor!</p>
                </div>

                <div class="form-pagamento" v-else>
                    <div class="row">
                        <div class="col-md-4 pack-image">
                            <img :src="`/images/pack-${packImage[packId]}-items.png`" class="image-pack" alt="" />
                        </div>

                        <div class="col-md-8 form-side card shadow">
                            <br><h3 class="card-title">Continuar pagamento</h3>
                            <form class="form-horizontal" @submit.prevent="createPayment">
                                <div class="form-group">
                                    <label for="name">Nome:</label>
                                    <input type="text" name="name" class="form-control" v-model="name">
                                </div>
                                <div class="form-group">
                                    <label for="email">E-mail:</label>
                                    <input type="text" name="email" required class="form-control" v-model="email">
                                </div>
                                <div class="form-group">
                                    <label for="cpf">CPF:</label>
                                    <input type="text" name="cpf" class="form-control" v-model="cpf">
                                </div>
                                <div class="form-group">
                                    <label for="wallet">Endereço de Carteira</label>
                                    <select required name="select-wallet" @change="getAddress()" v-model="select_wallet" id="select-wallet" class="form-control">
                                        <option :value="0">Tenho a minha</option>
                                        <option :value="1">Crie para mim</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <input type="text" name="wallet" required class="form-control" v-model="wallet" v-if="select_wallet!=null" placeholder="Informe sua carteira aqui">
                                    <div v-if="warningPrivateMessage" class="alert alert-info" style="margin-top: 10px"> <p>  GUARDE ESSA CHAVE EM <strong>LOCAL SEGURO</strong>: <br> {{warningPrivateMessage}}</p> </div>
                                </div>

                                <input type="submit" class="btn btn-md btn-default" value="Realizar Pagamento" style="width:100%">

                                <br><br><span class="text-muted">Ao realizar pagamento você concorda com nossa <router-link target="_blank" to="/privacidade"> política de privacidade </router-link> </span>
                            </form>

                            <br><div class="alert alert-danger" v-if="errorMessage"> {{errorMessage }} </div>
                            <br><div class="alert alert-success" v-if="msg"> {{msg }} </div>

                            <p v-if="loadingPix"> <img src="/loader.svg" width="100" /> </p>
                            
                            <div class="qrcode-block" v-if="qrCodeGenerated">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p v-if="loadingPix">Aguarde, carregando informações...</p>
                                        <img :src="qrCodeGenerated" v-else />
                                    </div>
                                    <div class="col-md-8">
                                        <li>Abra o app do seu banco e vá na área do PIX;</li>
                                        <li>Leia o QR Code ou copie e cole no seu aplicativo o código abaixo;</li>
                                        <li>Efetue o pagamento e aguarde!</li>
                                        <br><input class="form-control pix-copia-cola" :value="copyPastePix" />

                                        <br>
                                        <p v-if="msgCopiedCode"> <span class="alert alert-success"> {{msgCopiedCode}} </span> </p>
                                        <a href="javascript:void(0)" @click="copyPixCode()" class="btn btn-md btn-info" style="width: 100%">Copiar código</a><br><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Base>
    </div>
</template>

<script>
import Base from "@/layouts/Base.vue";
import axios from '../api';
export default {
    components: {
        Base,
    },
    data() {
        return {
            packId: '',
            cpf: '',
            email: '',
            wallet: '',
            name: '',
            qrCodeGenerated: '',
            msg: '',
            errorMessage: '',
            loadingPix: false,
            packImage: ['1', '3', '5', '10'],
            copyPastePix: '',
            msgCopiedCode: '',
            warningPrivateMessage: '',
            select_wallet: null,
        }
    },
    methods: {
        async getAddress() {
            if( this.select_wallet == 1) {
                const wallet = await axios.get('/generate-wallet');
                this.wallet = wallet.data.address
                this.warningPrivateMessage = wallet.data.privateKey
            }
            if( this.select_wallet == 0) {
                this.wallet = ''
                this.warningPrivateMessage = ''
            }
        },
        validarCPF(cpf) {
            let add;
            let rev;
            cpf = cpf.replace(/[^\d]+/g,'');	
            if(cpf == '') return false;	
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 || 
                cpf == "00000000000" || 
                cpf == "11111111111" || 
                cpf == "22222222222" || 
                cpf == "33333333333" || 
                cpf == "44444444444" || 
                cpf == "55555555555" || 
                cpf == "66666666666" || 
                cpf == "77777777777" || 
                cpf == "88888888888" || 
                cpf == "99999999999")
                    return false;		
            // Valida 1o digito	
            add = 0;	
            for (let i=0; i < 9; i ++)		
                add += parseInt(cpf.charAt(i)) * (10 - i);	
                rev = 11 - (add % 11);	
                if (rev == 10 || rev == 11)		
                    rev = 0;	
                if (rev != parseInt(cpf.charAt(9)))		
                    return false;		
            // Valida 2o digito	
            add = 0;	
            for (let i = 0; i < 10; i ++)		
                add += parseInt(cpf.charAt(i)) * (11 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)	
                rev = 0;	
            if (rev != parseInt(cpf.charAt(10)))
                return false;		
            return true;   
        },
        async createPayment() {
            this.msgCopiedCode = ''
            this.errorMessage = ''
            let {email, cpf, wallet, name} = this

            if(!this.validarCPF(cpf)) {
                this.errorMessage = `Confira se seu CPF está correto e tente novamente.`
                return false
            }
            
            this.loadingPix = true

            let data = {email, cpf, wallet, name, packId: this.packId}
            let createPixPayment = await axios.post(`/order`, data)

            if(createPixPayment.data.warning) {
                this.errorMessage = createPixPayment.data.msg
            } else if(createPixPayment.status == 200) {
                this.qrCodeGenerated = createPixPayment.data.imagemQrcode
                this.copyPastePix = createPixPayment.data.qrcode
            }
            this.loadingPix = false
        },
        async copyPixCode() {
            try {
                await navigator.clipboard.writeText(this.copyPastePix);
                this.msgCopiedCode = `Código copiado!`
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        }
    },
    mounted() {
        this.packId = this.$route.query.pack || null
    }
}
</script>