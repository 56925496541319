<template>
    <div class="main">
        <Base>
        <section>
            <div class="text-center hero-txt">
                <h1 class="display-4 headline">O TEMPO É UM ENIGMA...</h1>
                <p> "O que aconteceu com o Calango Doido?" </p>
                <a class="btn btn-default btn-lg" role="button">18/09 • AO VIVO</a>
            </div>
        </section>

        <section class="packs" id="packs-section">
            <div class="row">
                <div class="col text-center">
                    <Countdown end="September 18, 2023"></Countdown>
                </div>
            </div>
        </section>

        </Base>
    </div>
</template>

<script>
import Base from "@/layouts/Base.vue";
import Countdown from '@/components/Countdown.vue'
export default {
    components: {
        Base,
        Countdown
    },
    methods: {
        choosePack(id) {
            this.$router.push({path: `pagamento?pack=${id}`})
        },
    },
}

</script>

<style scoped>
.main > div {
  background-color: #F3F3F3;
  height: 100vh;
}
.partners, .community, .club {
    padding: 7rem 0;
}
.swag, .moviment { padding: 2rem 10px; }
.partners h2 {
    padding-bottom: 50px;
}
.partners img {
    width: 100px;
    margin: 10px auto;
    filter: grayscale(1);
}

.partners .card {
    background-color: transparent;
    border: none;
}

.community-btn {
    margin: 10px;
}

.community-btn {
    border: 1px solid #E3E3E3;
}

.bt-telegram:hover {
    background-color: #36b5f0;
    color: #F3F3F3;
}

.club {
    background-color: #EEE;
    border-radius: 7px;
    padding: 30px;
}

@media screen and (max-width:900px) {
    
    .headline {
        font-size: 40px;
    }

}

</style>