import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Pagamento from '../views/Pagamento.vue'
import Privacidade from '../views/Privacidade.vue'
import TermosDeUso from '../views/TermosDeUso.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pagamento',
    name: 'Pagamento',
    component: Pagamento
  },
  {
    path: '/privacidade',
    name: 'Privacidade',
    component: Privacidade
  },
  {
    path: '/termos-de-uso',
    name: 'TermosDeUso',
    component: TermosDeUso
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  // mode: 'history'
})

export default router
